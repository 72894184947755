import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
  FormHelperText,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { useState } from 'react';
import { IDeleteSubdomainRequest } from './types';
import { useDeleteSubdomainMutation } from './websiteApi';
import { deleteSubdomainValidationSchema } from './validationSchema';
import ContentContainer from '../layout/ContentContainer';

function DeleteSubdomain() {
  const [result, setResult] = useState<undefined | boolean>(undefined);
  const [deleteSubdomain] = useDeleteSubdomainMutation();

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IDeleteSubdomainRequest>({
    resolver: yupResolver(deleteSubdomainValidationSchema),
  });

  const onSubmit = async (values: IDeleteSubdomainRequest) => {
    setResult(undefined);

    try {
      const res = await deleteSubdomain({
        ...values,
      }).unwrap();

      if (res.success) {
        reset();
      }

      setResult(res.success);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContentContainer heading="Delete Subdomain">
      <Button
        as={RouterLink}
        to="/website"
        variant="ghost"
        colorScheme="blue"
        aria-label="All Websites"
        leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        mb={6}
      >
        All Websites
      </Button>
      {result !== undefined && (
        <Alert status={result === true ? 'success' : 'error'} mb="3" variant="solid" rounded="md">
          <AlertIcon />
          <AlertDescription>
            {result === true
              ? 'The subdomain has been removed from the DNS records.'
              : 'Failed to remove the subdomain from the DNS records.'}
          </AlertDescription>
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="3">
          <FormControl isRequired isInvalid={!!errors.subdomain}>
            <FormLabel htmlFor="subdomain">Subdomain</FormLabel>
            <Input id="subdomain" placeholder="subdomain" {...register('subdomain')} />
            <FormHelperText>
              Do not include the &quot;aftermarketwebsites.com&quot; domain in the subdomain.
            </FormHelperText>
            <FormErrorMessage>{errors.subdomain && errors.subdomain.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Button mt={6} colorScheme="green" isLoading={isSubmitting} type="submit">
          Submit
        </Button>
      </form>
    </ContentContainer>
  );
}

export default DeleteSubdomain;
