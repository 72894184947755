import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../app/axiosBaseQuery';
import { getApiUrl } from '../../envUtils';
import {
  IChangeDomainRequest,
  IChangeDomainResponse,
  IDeleteSubdomainRequest,
  IDeleteSubdomainResponse,
  ICreateWebsiteRequest,
  ICreateWebsiteResponse,
  IWebsite,
} from './types';

interface IDeleteResponse {
  success: boolean;
  id: number;
}

export const websiteApi = createApi({
  reducerPath: 'websiteApi',
  baseQuery: process.env.VITEST ? fetchBaseQuery({ baseUrl: getApiUrl() }) : axiosBaseQuery(),
  tagTypes: ['Websites'],
  endpoints: (builder) => ({
    getWebsites: builder.query<IWebsite[], void | undefined | number>({
      query: (serverId) => ({
        url: 'website',
        method: 'GET',
        params: { server_id: serverId },
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Websites', id } as const)), { type: 'Websites', id: 'LIST' }]
          : [{ type: 'Websites', id: 'LIST' }],
    }),
    getWebsite: builder.query<IWebsite, number>({
      query: (id) => ({
        url: `website/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Websites', id }],
    }),
    createWebsite: builder.mutation<ICreateWebsiteResponse, ICreateWebsiteRequest>({
      query: (data) => ({
        url: 'website',
        method: 'POST',
        data,
      }),
    }),
    changeDomain: builder.mutation<IChangeDomainResponse, IChangeDomainRequest>({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `website/changeDomain/${id}`,
          method: 'PUT',
          data: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Websites', id }],
    }),
    deleteSubdomain: builder.mutation<IDeleteSubdomainResponse, IDeleteSubdomainRequest>({
      query: (data) => {
        const { ...body } = data;
        return {
          url: `website/deleteSubdomain`,
          method: 'DELETE',
          data: body,
        };
      },
    }),
    deleteWebsite: builder.mutation<IDeleteResponse, number>({
      query: (id) => ({
        url: `website/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Websites', id }],
    }),
  }),
});

export const {
  useGetWebsitesQuery,
  useGetWebsiteQuery,
  useCreateWebsiteMutation,
  useChangeDomainMutation,
  useDeleteWebsiteMutation,
  useDeleteSubdomainMutation,
} = websiteApi;
