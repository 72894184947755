import Brands from '../features/brand/Brands';
import Deployments from '../features/deployment/Deployments';
import Scripts from '../features/script/Scripts';
import SiteUsers from '../features/siteUser/SiteUsers';
import CreateWebsite from '../features/website/CreateWebsite';
import Websites from '../features/website/Websites';
import SystemUsers from '../features/systemUser/SystemUsers';
import AddSystemUser from '../features/systemUser/AddSystemUser';
import EditSystemUser from '../features/systemUser/EditSystemUser';
import ClientMap from '../features/clientMap/ClientMap';
import SettingUsage from '../features/script/settingUsage/SettingUsage';
import BrandUsage from '../features/script/brandUsage/BrandUsage';
import PaymentGatewayUsage from '../features/script/paymentGatewayUsage/PaymentGatewayUsage';
import ShippingGatewayUsage from '../features/script/shippingGatewayUsage/ShippingGatewayUsage';
import IntegrationUsage from '../features/script/integrationUsage/IntegrationUsage';
import CustomBrandPageUsage from '../features/script/customBrandPageUsage/CustomBrandPageUsage';
import GenerateBrandFile from '../features/script/generateBrandFile/GenerateBrandFile';
import CreateDemoSnapshot from '../features/script/createDemoSnapshot/CreateDemoSnapshot';
import UnavailableBrands from '../features/brand/UnavailableBrands';
import GlobalBrands from '../features/brand/GlobalBrands';
import WebsiteBrands from '../features/brand/WebsiteBrands';
import BulkEditBrands from '../features/brand/BulkEditBrands';
import ChangeDomain from '../features/website/ChangeDomain';
import GlobalInstantRebates from '../features/instantRebate/GlobalInstantRebates';
import AddGlobalInstantRebate from '../features/instantRebate/AddGlobalInstantRebate';
import EditGlobalInstantRebate from '../features/instantRebate/EditGlobalInstantRebate';
import UserLogins from '../features/script/userLogins/UserLogins';
import BrandFilters from '../features/brand/BrandFilters';
import AddBrandFilter from '../features/brand/AddBrandFilter';
import EditBrandFilter from '../features/brand/EditBrandFilter';
import DeleteBrands from '../features/script/deleteBrands/DeleteBrands';
import DeleteSubdomain from '../features/website/DeleteSubdomain';

interface IRoute {
  path: string;
  Component: () => JSX.Element;
  routes?: IRoute[];
}

const routes: IRoute[] = [];

// Websites
routes.push({
  path: 'website',
  Component: Websites,
  routes: [
    { path: 'create', Component: CreateWebsite },
    { path: 'delete-subdomain', Component: DeleteSubdomain },
    { path: 'change/:websiteId', Component: ChangeDomain },
  ],
});

// Scripts
routes.push({
  path: 'script',
  Component: Scripts,
  routes: [
    { path: 'setting-usage', Component: SettingUsage },
    { path: 'brand-usage', Component: BrandUsage },
    { path: 'delete-brands', Component: DeleteBrands },
    { path: 'payment-gateway-usage', Component: PaymentGatewayUsage },
    { path: 'shipping-gateway-usage', Component: ShippingGatewayUsage },
    { path: 'integration-usage', Component: IntegrationUsage },
    { path: 'custom-brand-page-usage', Component: CustomBrandPageUsage },
    { path: 'user-logins', Component: UserLogins },
    { path: 'generate-brand-file', Component: GenerateBrandFile },
    { path: 'create-demo-snapshot', Component: CreateDemoSnapshot },
  ],
});

// Users
routes.push({
  path: 'site-user',
  Component: SiteUsers,
});

// Deployments
routes.push({
  path: 'deployment',
  Component: Deployments,
});

// Brands
routes.push({
  path: 'brand',
  Component: Brands,
  routes: [
    { path: 'unavailable', Component: UnavailableBrands },
    { path: 'global', Component: GlobalBrands },
    { path: 'bulk', Component: BulkEditBrands },
    { path: 'filter/add', Component: AddBrandFilter },
    { path: 'filter/:id', Component: EditBrandFilter },
    { path: 'filter', Component: BrandFilters },
    { path: ':websiteId', Component: WebsiteBrands },
  ],
});

// System Users
routes.push({
  path: 'system-user',
  Component: SystemUsers,
  routes: [
    { path: 'add', Component: AddSystemUser },
    { path: ':systemUserId', Component: EditSystemUser },
  ],
});

// Client Map
routes.push({
  path: 'client-map',
  Component: ClientMap,
});

// Global Instant Rebates
routes.push({
  path: 'global-instant-rebate',
  Component: GlobalInstantRebates,
  routes: [
    { path: 'add', Component: AddGlobalInstantRebate },
    { path: ':uuid', Component: EditGlobalInstantRebate },
  ],
});

export default routes;
