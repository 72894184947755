/* eslint-disable react/no-unstable-nested-components */
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ColumnDef, Row } from '@tanstack/react-table';
import {
  Center,
  Link,
  Flex,
  Spacer,
  Tooltip,
  Button,
  useColorMode,
  IconButton,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import capitalize from '../../helpers/capitalize';
import thousands from '../../helpers/thousands';
import ContentContainer from '../layout/ContentContainer';
import ThemedSyncLoader from '../loader/ThemedSyncLoader';
import { IWebsite } from './types';
import { useGetWebsitesQuery } from './websiteApi';
import DataTable from '../table/DataTable';
import ProductUpdaterPopover from './productUpdaterPopover/ProductUpdaterPopover';
import { useLoginQuery } from '../auth/authApi';
import CustomButtonGroup from '../buttonGroup/CustomButtonGroup';

function Websites() {
  const { data: currentUser } = useLoginQuery();
  const jwt = currentUser?.jwt ?? '';
  const { colorMode } = useColorMode();
  const linkColor = colorMode === 'dark' ? 'blue.300' : 'blue.600';
  const loginColor = colorMode === 'dark' ? 'cyan.300' : 'cyan.600';
  const { data, isLoading } = useGetWebsitesQuery();
  const permissions = currentUser ? currentUser.authenticatedUser.permissions : [];
  const canCreateWebsites = permissions.includes('create_websites');
  const canChangeDomains = permissions.includes('change_domains');

  const columns = useMemo<ColumnDef<IWebsite>[]>(
    () => [
      {
        header: 'Domain',
        accessorFn: ({ domain }) => domain.toLowerCase(),
        cell: ({ row }) => {
          const domain = row.original.domain.toLowerCase();
          const url = `https://${domain}/`;

          return (
            <Flex alignItems="center" gap="1">
              <Link href={url} color={linkColor} isExternal>
                {domain}
              </Link>
              <Spacer />
              <Tooltip label="Log in" hasArrow>
                <Link href={`${url}admin/index.php?bypass=${jwt}`} color={loginColor} isExternal>
                  <FontAwesomeIcon icon={faRightToBracket} />
                </Link>
              </Tooltip>
            </Flex>
          );
        },
      },
      {
        header: 'Database',
        accessorFn: ({ database_name }) => database_name.toLowerCase(),
      },
      {
        header: 'Server',
        accessorFn: ({ server }) => server.name,
      },
      {
        header: 'Plan',
        accessorFn: ({ plan }) => capitalize(plan),
      },
      {
        header: 'Seller Type',
        accessorFn: ({ seller_type }) => capitalize(seller_type),
      },
      {
        header: 'Currency',
        accessorKey: 'currency',
      },
      {
        header: 'Country',
        accessorKey: 'country',
      },
      {
        header: 'Total Products',
        accessorFn: ({ total_products }) => thousands(total_products),
        sortingFn: (rowA: Row<IWebsite>, rowB: Row<IWebsite>) => {
          const rowATotalProducts = rowA.original.total_products;
          const rowBTotalProducts = rowB.original.total_products;

          if (rowATotalProducts === rowBTotalProducts) {
            return 0;
          }

          return rowATotalProducts < rowBTotalProducts ? -1 : 1;
        },
        meta: {
          isNumeric: true,
        },
      },
      {
        header: 'Product Updater',
        id: 'product_updater',
        cell: ({ row }) => <ProductUpdaterPopover website={row.original} />,
      },
      {
        header: '',
        id: 'actions',
        cell: ({ row }) =>
          canChangeDomains && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Actions"
                icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                variant="subtle"
              />
              <MenuList>
                <MenuItem as={RouterLink} to={`/website/change/${row.original.id}`}>
                  Change Domain
                </MenuItem>
              </MenuList>
            </Menu>
          ),
      },
    ],
    [linkColor, loginColor, jwt, canChangeDomains],
  );

  return (
    <ContentContainer heading="Websites" fullWidth>
      {canCreateWebsites && (
        <CustomButtonGroup mb="6">
          <Button as={RouterLink} to="/website/create" colorScheme="green">
            Create Website
          </Button>
          <Button as={RouterLink} to="/website/delete-subdomain" colorScheme="red">
            Delete Subdomain
          </Button>
        </CustomButtonGroup>
      )}
      {isLoading && (
        <Center>
          <ThemedSyncLoader />
        </Center>
      )}
      {data && <DataTable columns={columns} data={data} />}
    </ContentContainer>
  );
}

export default Websites;
